/* eslint-disable react-hooks/exhaustive-deps */
import { USER_NOT_AUTHENTICATED_ERROR } from '@loggi/authentication-lib';
import { Box, Button, Link, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { Auth, Hub } from 'aws-amplify';
import getFeatureSwitchValue from 'configuration/get-feature-switch-value';
import SWITCHES from 'configuration/switches';
import {
  COGNITO_ERROR_MESSAGES,
  COGNITO_LOGIN_FLOW_TYPE,
  ERROR_MESSAGES_PT,
  USER_WITH_INCOMPLETE_SIGNUP
} from 'constants/error-messages';
import {
  NEXT_PARAMETER_NAME,
  REDIRECT_STORAGE_KEY
} from 'constants/string-keys';
import SignIn from 'containers/sign-in/sign-in.component';
import useToastNotification from 'hooks/toast-notification/toast.hook';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from 'routes/routes';
import { authenticateWebAndRedirect } from 'service/migration-api';
import Header from 'UI/components/header/header.component';
import { setSentryContext } from '../../../sentry/sentry-context';

export const SIGN_UP_BUTTON_ID = 'sign-up-button-id';
export const FORGOT_PASSWORD_BUTTON_ID = 'forgot-password-button-id';
export const FORBIDDEN_AUTHENTICATION_ALERT_ID =
  'forbidden-authentication-alert-id';
export const USER_NEEDS_INVITE_OR_SIGN_UP_ALERT_ID =
  'user-needs-invite-or-sign-up-alert-id';

const HELP_LINK = 'https://ajuda.loggi.com/hc/pt-br?utm_source=sso';
const NEW_HELP_REQUEST_LINK =
  'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=448027&utm_source=sso';
const ENVIOS_SIGNIN_LINK = 'https://www.loggi.com/contas/criar/';

const ForbiddenAuthenticationAlert = () => (
  <Box
    display="inline"
    data-testid={FORBIDDEN_AUTHENTICATION_ALERT_ID}
    data-gtm-error={FORBIDDEN_AUTHENTICATION_ALERT_ID}
  >
    Há um problema com sua conta. Entre em contato com a nossa
    <span>&nbsp;</span>
    <Link target="_blank" href={NEW_HELP_REQUEST_LINK}>
      Central de Ajuda
    </Link>
    .
  </Box>
);

const UserNeedsInviteOrSignUpAlert = () => (
  <Box display="inline" data-testid={USER_NEEDS_INVITE_OR_SIGN_UP_ALERT_ID}>
    {ERROR_MESSAGES_PT.NEEDS_INVITE_OR_SIGN_UP}
    <span>&nbsp;</span>
    <Link target="_blank" href={ENVIOS_SIGNIN_LINK}>
      Cadastrar agora
    </Link>
    .
  </Box>
);

function Home({ setLoading }) {
  const showToastNotification = useToastNotification();
  const [session, setSession] = useState(null);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [redirectFragmentLoading, setRedirectFragmentLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const redirectLink = localStorage.getItem(REDIRECT_STORAGE_KEY);
  const query = new URLSearchParams(location.search);
  const defaultRedirectUrl =
    process.env.REACT_APP_LOGGI_URL +
    process.env.REACT_APP_DEFAULT_CORP_APP_REDIRECT;

  if (!redirectLink || query.has(NEXT_PARAMETER_NAME)) {
    localStorage.setItem(
      REDIRECT_STORAGE_KEY,
      query.get(NEXT_PARAMETER_NAME) || defaultRedirectUrl
    );
  }

  function handleLogout() {
    history.push(ROUTES.logout);
  }

  function generateRequestOptions(jwtTokenInput, userEmail) {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ jwtToken: jwtTokenInput, email: userEmail }),
      credentials: 'include'
    };
  }

  const getUserAndCompanyFromSession = () => {
    if (!session?.idToken?.payload) return;

    const authenticatedUser = session.idToken.payload;
    const userAccess = JSON.parse(
      authenticatedUser['custom:access'] ||
        authenticatedUser?.challengeParam?.userAttributes['custom:access'] ||
        null
    );
    const companyAccess = userAccess?.companies || [];

    const user = { ...authenticatedUser, companyAccess };

    const companies = user?.companyAccess ?? [];
    const company = companies[0] ?? null;
    setSentryContext({ user, company });
  };

  const handleCognitoSessionCheck = async () => {
    setSessionLoading(true);
    Auth.currentSession()
      .then(currentSession => {
        setSessionLoading(false);
        if (!currentSession || session) {
          return;
        }
        setSession(currentSession);
      })
      .catch(() => setSessionLoading(false));
  };

  const redirectIfActiveSession = useCallback(async () => {
    const idToken = session?.getIdToken();
    if (!idToken) {
      return;
    }
    const jwtToken = idToken.getJwtToken();
    const userEmail = idToken?.payload?.email || '';
    const requestOptions = generateRequestOptions(jwtToken, userEmail);
    const logToSentry = await getFeatureSwitchValue(
      SWITCHES.sendCaughtErrorsToSentry
    );
    setLoading(true);
    authenticateWebAndRedirect(requestOptions)
      .then(() => {
        setLoading(true);
      })
      .catch(err => {
        setLoading(false);

        const errorMessages = {
          [ERROR_MESSAGES_PT.FORBIDDEN]: <ForbiddenAuthenticationAlert />,
          [ERROR_MESSAGES_PT.NEEDS_INVITE_OR_SIGN_UP]: (
            <UserNeedsInviteOrSignUpAlert />
          )
        };

        const alertContent = errorMessages[err.message] || err.message;

        if (logToSentry) {
          Sentry.captureMessage(err.message);
        }

        if (err.message === USER_WITH_INCOMPLETE_SIGNUP) {
          history.push(ROUTES.completeSignUp);
        } else {
          showToastNotification(alertContent, 'error');
        }
      });
  }, [session, setLoading, showToastNotification]);

  const handleEmailPasswordRedirect = async payload => {
    if (!payload || payload === USER_NOT_AUTHENTICATED_ERROR) return;
    if (
      payload?.data?.authenticationFlowType ===
      COGNITO_LOGIN_FLOW_TYPE.EMAIL_PASSWORD_LOGIN
    ) {
      await handleCognitoSessionCheck();
      await redirectIfActiveSession();
    }
  };

  const handleSignInFailure = async payload => {
    // eslint-disable-next-line no-console
    console.log('HANDLE SIGN IN ERROR');
    // eslint-disable-next-line no-console
    console.log(payload);
    if (!payload || payload === USER_NOT_AUTHENTICATED_ERROR) return;

    let errorMessage;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(COGNITO_ERROR_MESSAGES)) {
      if (payload?.data?.message?.includes(value)) {
        errorMessage = ERROR_MESSAGES_PT[key];
      }
      if (errorMessage === ERROR_MESSAGES_PT.SIGN_UP_GOOGLE_LINK) {
        redirectIfActiveSession();
      }
    }
    if (!errorMessage) {
      errorMessage = ERROR_MESSAGES_PT.GENERIC;
      Sentry.captureMessage(
        `${errorMessage} -- PAYLOAD MSG: ${
          payload?.data?.message
        } -- PAYLOAD CODE: ${payload?.data?.code}`
      );
    }

    showToastNotification(errorMessage, 'error');
    const logToSentry = await getFeatureSwitchValue(
      SWITCHES.sendCaughtErrorsToSentry
    );
    if (logToSentry && errorMessage !== ERROR_MESSAGES_PT.GENERIC) {
      Sentry.captureMessage(
        `Cognito Error: ${payload?.data?.message} - Error msg: ${errorMessage}`
      );
    }
  };

  const getFragmentToken = urlFragment => {
    if (!urlFragment) return false;
    const URLFragmentWithoutHash = urlFragment.slice(1);
    if (!URLFragmentWithoutHash.startsWith('t=')) return false;
    return URLFragmentWithoutHash.slice(2);
  };

  const authenticateAndRedirectFromURLFragment = useCallback(() => {
    const urlFragment = window.location.hash;
    const token = getFragmentToken(urlFragment);
    if (token) {
      setRedirectFragmentLoading(true);
      const requestOptions = generateRequestOptions(token);
      authenticateWebAndRedirect(requestOptions).catch(err => {
        setRedirectFragmentLoading(false);
        const message = `Failure automatically authenticating using fragment "${urlFragment}": ${
          err.message
        } `;
        Sentry.captureMessage(message);
      });
    }
  }, []);

  useEffect(async () => {
    if (session) {
      getUserAndCompanyFromSession();
      await redirectIfActiveSession();
      return;
    }
    await handleCognitoSessionCheck();
  }, [session]);

  useEffect(authenticateAndRedirectFromURLFragment, [
    authenticateAndRedirectFromURLFragment
  ]);

  useEffect(() => {
    setLoading(loading => {
      const isLoadingStillTheSame =
        (redirectFragmentLoading || sessionLoading) === loading;

      return isLoadingStillTheSame
        ? loading
        : redirectFragmentLoading || sessionLoading;
    });
  }, [redirectFragmentLoading, sessionLoading]);

  useEffect(() => {
    // Do not check session if trying to authenticate from fragment
    if (getFragmentToken(window.location.hash)) return;
    Hub.listen('auth', ({ payload }) => {
      // eslint-disable-next-line no-console
      console.log('HUB');
      // eslint-disable-next-line no-console
      console.log(payload);
      switch (payload.event) {
        case 'signIn':
          handleEmailPasswordRedirect(payload)
            .then(() => {
              setLoading(false);
            })
            .catch(err => {
              Sentry.captureMessage(err.message);
            });
          break;
        case 'signIn_failure':
          handleSignInFailure(payload)
            .then(() => {
              setLoading(false);
            })
            .catch(err => {
              Sentry.captureMessage(err.message);
            });
          break;
        // no default
      }
    });
  }, [redirectIfActiveSession, showToastNotification, setLoading]);
  return (
    <Box height="100vh" display="flex" flexDirection="column" px={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={1}
        flexDirection="column"
        height="100%"
      >
        <Header />
        <Typography variant="h4">
          Entregamos pra você em todo o Brasil.
        </Typography>
        {session && (
          <Button variant="outlined" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        )}
        {!session && <SignIn setLoading={setLoading} />}
        <Box pb={3} textAlign="center">
          <Typography variant="body2" bgcolor="pink">
            Precisa de ajuda?
            <Link
              href={HELP_LINK}
              color="primary"
              underline="none"
              target="_blank"
            >
              <strong> Fale conosco</strong>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

Home.propTypes = {
  setLoading: PropTypes.func.isRequired
};
export default Home;
